* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 9px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(51, 51, 51); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid rgb(184, 184, 184); /* creates padding around scroll thumb */
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 1050px) {
  h1 {
    font-size: 2.6rem;
  }
}
@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}
